import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
import Image from 'src/components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function NoRole({ restricted }) {
  return (
    <Page title="404 Page Not Found" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Access Denied!
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Sorry, you can't view this page because you don't have the right permissions. Please check with your admin to request the necessary permissions.
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                We apologize for any inconvenience this may cause.
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Image src="assets/illustrations/illustration_403.png" width={260} height={260} />
            </m.div>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
