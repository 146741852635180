
import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';
import { Card, Grid } from '@mui/material';
// components
import { SkeletonWbsListInTable } from '../../components/skeleton'
import { useTheme } from '@mui/material/styles';
// import css 
import '../../sections/@dashboard/timesheet/TimeSheetNewForm.css';
import { DataGrid } from '@mui/x-data-grid';

const scrollbarStyles = `
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    background: #00ab569d;
    borderRadius: 5px;
  }
`;

export default function ResourceScheduleReports({ isMonthly, data, isLoading }) {
  const theme = useTheme();

  const [pageSize, setPageSize] = useState(10);

  const [cols, setCols] = useState([
    {
      field: "cname",
      headerName: "Resource",
      minWidth: 180,
      flex: 1,

    },
    {
      field: "res_plan_hrs",
      headerName: "Planned Hrs",
      minWidth: 200,
      flex: 1,
      renderCell: (cell) => `${cell.formattedValue} hrs`
    },
    {
      field: "res_bill_hrs",
      headerName: "Billable Hrs",
      minWidth: 150,
      flex: 1,
      renderCell: (cell) => `${cell.formattedValue} hrs`
    },
    {
      field: "unbill_hrs",
      headerName: "Non Billable Hrs",
      minWidth: 180,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (cell) => `${cell.formattedValue} hrs`

    },
    {
      field: "utilizastion",
      headerName: "Utlisation",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (cell) => `${cell.formattedValue}%`
    },
  ]);

  const initialStateOfRowsPerOpt = [10, 20, 30, 40, 50, { value: -1, label: 'All' }];
  const [rowsperoptions, setRowsperoptions] = useState(initialStateOfRowsPerOpt);
  const handlePageSizeChange = (newPageSize) => {
    setRowsperoptions(newPageSize === -1 ? [...initialStateOfRowsPerOpt, data.length] : initialStateOfRowsPerOpt);
    setPageSize(newPageSize === -1 ? data.length : newPageSize);
  };

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} md={12}>
        <Card sx={{ p: 3 }} key={isMonthly.toString()}>
          {isLoading && <SkeletonWbsListInTable /> ||
            <ThemeProvider theme={theme}>
              <GlobalStyles styles={`.MuiDataGrid-root ${scrollbarStyles}`} />
              <DataGrid
                rows={data}
                columns={cols}
                pageSize={pageSize}
                rowHeight={35}
                rowsPerPageOptions={rowsperoptions}
                onPageSizeChange={handlePageSizeChange}
                pagination
                disableSelectionOnClick
                autoHeight
              />
            </ThemeProvider>


          }
        </Card>

      </Grid>

    </Grid>



  );
}
