import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';


export default function SessionModal({ isOpen, L_VERSION }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: L_VERSION ? "90vw" : "auto",
        height: L_VERSION ? "75vh" : "auto",
        overflowY: "auto",
        bgcolor: 'rgba(0,0,0,0.4)',
        border: "1px solid rgba(0,0,0,0.4)",
        outline: "none",
        color: '#fff',
        borderRadius: "10px",
        boxShadow: 24,
        p: 2,
    };

    const redirectToLoginPage = () => {
        location.reload(true);
    };

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                // onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isOpen}>
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#333",
                                    padding: "2px",
                                    borderRadius: "50%",
                                    marginRight: "4px"
                                }}
                            ><WarningIcon sx={{ color: "rgb(255, 193, 7)", fontSize: "30px" }} /></Box>
                            <Typography sx={{ textAlign: "center" }} id="transition-modal-title" variant="h5" component="h1">
                                {!L_VERSION ? "Your session has expired" : "Keep Your App Up-to-Date! "}
                            </Typography>
                        </Box>
                        {/* <Typography id="transition-modal-description" sx={{ textAlign: "center", mt: 2 }}> */}
                        {
                            !L_VERSION && ("Please log in again to continue using the app") || (<>

                                <Typography id="transition-modal-description" sx={{ textAlign: "left", mt: 2 }}>
                                    To access the latest updates and improvements, it's essential to clear your browser's cache and perform a hard reload. Kindly follow the steps below to ensure you're using the most recent version of the app both in mobile and desktop:
                                </Typography>
                                <em>
                                    <Typography id="transition-modal-description" component={"h2"} sx={{ textAlign: "center", mt: 2 }}>
                                        For Desktop Users:
                                    </Typography>
                                    <strong>
                                        Clear Cache:<br />
                                    </strong>

                                    <p>To empty the cache and hard reload, you need to open PMS. Then, press the F12 key to open the Inspect Element section. Next, right-click on the reload button and select the Empty cache and hard reload option.</p><br />
                                    <strong>
                                        Hard Reload:<br />
                                    </strong>

                                    {`Press Ctrl + Shift + R (Windows/Linux) or Cmd + Shift + R (Mac).
                                        Alternatively, right-click on the refresh icon in the address bar and select "Clear Cache & Hard Reload".`}
                                </em>
                                <em>
                                    <Typography id="transition-modal-description" component={"h2"} sx={{ textAlign: "center", mt: 2 }}>
                                        For Mobile/Ipad Users:
                                    </Typography>

                                    <strong>
                                        Clear Cache:<br />
                                    </strong>
                                    <Typography id="transition-modal-description" component={"h5"} sx={{ textAlign: "left" }}>
                                        1. Tap on the three dots at the top right corner of the mobile browser.
                                    </Typography>

                                    <Typography id="transition-modal-description" component={"h5"} sx={{ textAlign: "left" }}>
                                        2. {`Move to Settings > Privacy > Clear browsing data.`}
                                    </Typography>

                                    <Typography id="transition-modal-description" component={"h5"} sx={{ textAlign: "left" }}>
                                        3. Select Cached images and files and Cookies and site data. Click on Clear data.
                                    </Typography>
                                </em>
                            </>)
                        }
                        {/* </Typography> */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: "30px",
                            }}
                        >

                            <Button variant="contained" onClick={() => redirectToLoginPage()}>{!L_VERSION && "Login" || "Update and Reload"}</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}