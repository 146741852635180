
// @mui
import { Box, Button, Container, Switch, Typography, TextField, IconButton, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// routes
import { useSnackbar } from 'notistack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PATH_DASHBOARD } from '../../routes/paths';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// hooks
import useSettings from '../../hooks/useSettings';
import * as Yup from 'yup';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { FormProvider, RHFSelect } from '../../components/hook-form';
// sections
import { useState, useEffect } from 'react';

import Axios from 'src/utils/Axios';
import API from 'src/utils/Api';
import DeliveryReportDetails from './DeliveryReportDetails';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';


// ----------------------------------------------------------------------

export default function ResourceSchedule() {
    const { themeStretch } = useSettings();
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [invStatusList, setInvStatusLists] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [data, setData] = useState([]);
    const [devStatusList, setDevStatusLists] = useState([]);
    const [isMonthly, setIsMonthly] = useState(false);
    const NewUserSchema = Yup.object().shape({});
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
    });

    const {
        reset,
        watch,
        getValues,
        handleSubmit,
        resetField,
    } = methods;
    const handleYearChange = (date) => {
        setSelectedYear(date);

    };
    const onSubmit = async () => {
        const userFormData = getValues();
    };
    const handleMonthChange = (date) => {
        setSelectedMonth(date);
    };
    function getdropdownlist() {
        Axios.GET(API.wbsDropDown)
            .then((result) => {
                setDevStatusList(result.dropdownList.devStatusDropdown);
                setInvStatusList(result.dropdownList.invStatusDropdown);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const setDevStatusList = (data) => {
        const wbsList = [...new Map(data.map((item) => [item.id, item])).values()];
        setDevStatusLists(wbsList);
    };

    const setInvStatusList = (data) => {
        const workList = [...new Map(data.map((item) => [item.id, item])).values()];
        setInvStatusLists(workList);
    };

    useEffect(() => {

        getAllProjects();
        getdropdownlist();
    }, [])

    const getAllProjects = async () => {
        try {
            const result = await Axios.GET(API.newProjectdata);
            setProjectList(result.projectData);
            console
            return result?.projectData || [];
        } catch (err) {
            console.error('Error in getAllProjects:', err);
            return [];
        }
    };
    useEffect(() => {
        watch("customer") && (setProjectDropdown(watch("customer")));
    }, [watch("customer"), location])
    useEffect(() => [
        getDeliveryReport()
    ], [selectedYear, selectedMonth, watch('devstatus'), watch('invstatus')]);

    function getDeliveryReport() {
        setisLoading(true)
        Axios.GET(API.getDeliveryReportList + `?year=${selectedYear ? new Date(selectedYear).getFullYear() : ''}&month=${selectedMonth ? new Date(selectedMonth).getMonth() + 1 : ''}&devstatus=${watch('devstatus') ? watch('devstatus') : ''}&invstatus=${watch('invstatus') ? watch('invstatus') : ''}`)
            .then((response) => {
                if (response) {
                    setData(response.finalData)
                    setisLoading(false)
                };
            })
            .catch((err) => {
                console.error(err)
                setisLoading(false)
            });
    };
    return (
        <Page title="PMS: Delivery Report">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Box sx={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: "space-between" }}>
                    <HeaderBreadcrumbs
                        heading="Delivery Report"
                        links={[
                            { name: 'Dashboard', href: PATH_DASHBOARD.root },
                            { name: `Delivery Report` }
                        ]}
                    />
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Box component={Stack} direction={'row'} rowGap={1} columnGap={1} flexWrap={'wrap'} sx={{ px: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={['year']}
                                    label="Year"
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    openTo="year"
                                    format="yyyy"
                                    renderInput={(params) => {
                                        return <TextField
                                            sx={{ width: 180, mr: 2 }}
                                            {...params} />
                                    }}
                                />
                                <DatePicker
                                    views={['month']}
                                    label="Month"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    openTo="month"
                                    format="MMMM yyyy"
                                    renderInput={(params) => {
                                        return <TextField
                                            {...params}
                                            sx={{ width: 180, mr: 2 }}
                                            inputProps={{
                                                readOnly: true,
                                                value: params.inputProps.value ? new Date(new Date().getFullYear(), new Date(params.inputProps.value).getMonth(), 1).toLocaleString('en-US', { month: 'long' }) : ''
                                            }}
                                        />
                                    }}
                                />
                            </LocalizationProvider>
                            <Box sx={{ display: 'flex', width: 180, px: 1 }}>
                                <RHFSelect name="devstatus" label="Development Status" placeholder="Development Status">
                                    <option value="" />
                                    {devStatusList.map((option) => (
                                        <option key={option.id} value={option.key}>
                                            {option.descr}
                                        </option>
                                    ))}
                                </RHFSelect>
                            </Box>
                            <Box sx={{ display: 'flex', width: 180, px: 1 }}>
                                <RHFSelect name="invstatus" label="Invoice Status" placeholder="Invoice Status">
                                    <option value="" />
                                    {invStatusList.map((option) => (
                                        <option key={option.id} value={option.key}>
                                            {option.descr}
                                        </option>
                                    ))}
                                </RHFSelect>

                            </Box>
                            {
                                (selectedMonth || selectedYear || watch('devstatus') || watch("invstatus")) ? <IconButton sx={{ color: "#F95959" }} onClick={() => { setSelectedMonth(null); setSelectedYear(null); resetField("devstatus", { defaultValue: "" }); resetField("invstatus", { defaultValue: "" }) }} aria-label="delete">
                                    <HighlightOffIcon sx={{ color: "#F95959" }} />
                                </IconButton> : null
                            }

                        </Box>
                    </FormProvider>
                </Box>

                <DeliveryReportDetails isMonthly={isMonthly} data={data} isLoading={isLoading} />
            </Container>
        </Page>
    );
}
