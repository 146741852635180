import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, IconButton } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import Badge, { badgeClasses } from '@mui/material/Badge';
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import { LoadingButton } from '@mui/lab';
import Axios from 'src/utils/Axios';
import API from 'src/utils/Api';
import { ERROR } from 'src/theme/palette';
import Label from 'src/components/Label';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      Axios.POST(API.Logout).then((result) => {
        setIsLoading(false);
        if (!result.isAuthenticated) {
          window.localStorage.clear();
          localStorage.setItem('authentication', false)
          window.location.reload();
          navigate('/');
        }
      }
      )
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    onToggleCollapse();
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography sx={{ color: "#00AB55", fontWeight: 700 }}>PMS</Typography>
          {/* {
            !isCollapse && (<Badge
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  top: 8,
                  right: -16,
                },
              }}
              badgeContent={
                <Link
                  href={'/'}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  sx={{ ml: 2 }}
                >
                  <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 1 }}>
                    {`v${localStorage.getItem('appv')}`}
                  </Label>
                </Link>
              }
            >
              <Typography sx={{ color: "#00AB55", fontWeight: 700 }}>PMS</Typography>
            </Badge>) || (
              <Typography sx={{ color: "#00AB55", fontWeight: 700 }}>PMS</Typography>
            )
          } */}

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
      {<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", py: 2 }}>
        {
          !isCollapse ? <Box>
            <Typography sx={{ fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column' }} color={'text.secondary'}>Version - {`${localStorage.getItem('appv')}`}</Typography>
            <LoadingButton onClick={() => handleLogout()} startIcon={<SettingsPowerIcon />} color='error' variant="outlined" loading={isLoading}>
              Log out
            </LoadingButton></Box> : <IconButton sx={{ width: 1, height: 1 }} ><SettingsPowerIcon sx={{ color: ERROR.main }} /></IconButton>
        }
      </Box>}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
