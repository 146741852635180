import { forwardRef, useEffect, useState } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import SessionModal from './components/SessionModal';
import Axios from './utils/Axios';
import API from './utils/Api';
import axios from "axios";
import Maintenance from './pages/Maintenance';
import NoRole from './pages/NoRole';
const { version } = require('../package.json');


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function App() {
  const [open, setOpen] = useState(false);
  const [downtime, setDowntime] = useState(true);
  const [noRole, setNoRole] = useState(true);
  const [showVersionAlert, setShowVersionAlert] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };


  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        localStorage.setItem("authentication", false);
        handleClick();
      };
      return Promise.reject(error);
    }
  );

  function getUser() {
    Axios.POST(API.Start).then((result) => {
      const isDowntime = result.downtime === "true";
      const hasNoRole = result.userRole === null;

      setDowntime(isDowntime);
      setNoRole(hasNoRole);

      if (isDowntime || hasNoRole) {
        return;
      };

      if (result) {
        localStorage.setItem("userRole", JSON.stringify(result.usermenu));
        localStorage.setItem("user", JSON.stringify(result));
      };
    }).catch((err) => {
      if (err) {
        localStorage.setItem("authentication", false);
        handleClick();
      };
    })
  };

  useEffect(() => {
    setShowVersionAlert(localStorage.getItem("appv") !== version);
    setTimeout(() => localStorage.setItem("appv", version), 2000);
    getUser();
  }, [])


  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <SessionModal isOpen={open} L_VERSION={false} />
                <SessionModal isOpen={showVersionAlert} L_VERSION={true} />
                <ScrollToTop />
                {downtime ? (
                  noRole ? (
                    <NoRole />
                  ) : (
                    <Maintenance />
                  )
                ) : (
                  <Router />
                )}
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
};

