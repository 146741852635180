import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  height: "95vh",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  border: 'none',
  outline: 'none',
  boxShadow: 'none',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  height: '95vh',
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Typography variant="h4" sx={{ mb: 1, color: '#444', fontWeight: 600 }}>
              Hi, Welcome Back
            </Typography>
            <img style={{ objectFit: "contain", height: "500px" }} src="/static/illustrations/illustration_login1.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography sx={{ color: 'text.secondary', fontWeight: 600, mr: 1 }} variant="h5" gutterBottom>
                Sign in to
              </Typography>
              <Typography sx={{ color: '#1976d2', fontWeight: 700 }} variant="h4" gutterBottom>
                PMS
              </Typography>
            </Box>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
