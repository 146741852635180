// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';


export default function RHFTextField({ name,style, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField sx={style} {...field} fullWidth autoComplete="off" error={!!error} helperText={error?.message} {...other} />
      )}
    />
  );
}
