// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';


export default function ThemeLocalization({ children }) {
  const defaultTheme = useTheme();
  const theme = createTheme(defaultTheme, "en");

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
