// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const user = JSON.parse(localStorage.getItem("user"))?.user;

  return (
    <Avatar
      src={""}
      alt={user?.firstname}
      color={createAvatar(user?.firstname).color}
      {...other}
    >
      {createAvatar(user?.firstname).name}
    </Avatar>
  );
}
